<template>
  <div class="c-activeity-share-record">
    <n-tabs type="line" :default-value="activityTab"
>
      <n-tab-pane name="activity" tab="活動紀錄">
        <div class="activity-wrap">
          <ul class="activity-list">
            <li class="activity-list__item" v-for="item of activityData" :key="item.period">
              <div class="activity-list__item__box">
                <p class="activity-list__item__box__title">{{ item.period }}</p>
                <ul class="activity-detail-list" v-for="detail of item.record" :key="detail.time">
                  <li class="activity-detail-list__item">
                    <p class="activity-detail-list__item__title">{{ detail.title }}</p>
                    <p class="activity-detail-list__item__time">{{ detail.time }}</p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </n-tab-pane>
      <n-tab-pane name="share" tab="分享紀錄">
        <div class="share-wrap">
          <div class="share-input">
            <p class="share-input__to">To</p>
            <div class="share-input__box">
              <input class="share-input__box__input" />
              <button class="add-btn">
                <img class="add-btn__icon" src="@/assets/myMusic/icon_invite.svg" />
                <span class="add-btn__text">Share</span>
              </button>
            </div>
          </div>
          <div class="share-list-wrap">
            <p class="total-share-count">
              Sharing with: {{shareData.length}}
            </p>
            <ul class="share-list">
              <li class="share-list__item" v-for="item of shareData" :key="item.time">
                <div class="share-list__item__box">
                  <div class="share-list__item__box__left">
                    <img class="left__img" src="@/assets/profile/img_profile.png" />

                    <div class="left__info">
                      <p class="left__info__title">{{ item.title }}</p>
                      <p class="left__info__time">{{ item.time }}</p>
                    </div>
                  </div>
                  <div class="share-list__item__box__right">
                    <div class="right__resend">
                      <p class="right__resend__text">Resend</p>
                    </div>
                    <img class="right__delete" src="@/assets/myMusicSidebar/note_delete.svg" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </n-tab-pane>
    </n-tabs>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { NTabs, NTabPane } from 'naive-ui';

export default defineComponent({
  name: 'ActivityShareRecord',
  components: {
    NTabs,
    NTabPane,
  },
  props: {
    activityTab: {
      type: String,
      default: '',
    },
  },
  setup() {
    const activityData = [
      {
        period: '本週',
        record: [
          {
            title: '與 alley.zhan@howdesign.com.tw 分享',
            time: '14 Sep 2021 11:24 am',
          },
        ],
      },

      {
        period: '上個月',
        record: [
          {
            title: '刪除 Home Sweet Home',
            time: '15 Sep 2021 11:24 am',
          },
          {
            title: '下載歌單',
            time: '13 Sep 2021 11:24 am',
          },
        ],
      },
      {
        period: '過去六個月',
        record: [
          {
            title: '刪除 Home Sweet Home',
            time: '17 Sep 2021 11:24 am',
          },
          {
            title: '下載歌單',
            time: '19 Sep 2021 11:24 am',
          },
        ],
      },
    ];

    const shareData = [
      {
        title: 'alley.zhan@howdesign.com.tw',
        time: '17 Sep 2021 11:24 am',
      },
      {
        title: 'ann@howdesign.com.tw',
        time: '18 Sep 2021 11:24 am',
      },
      {
        title: 'kyven@howdesign.com.tw',
        time: '19 Sep 2021 11:24 am',
      },
      {
        title: 'lynn@howdesign.com.tw',
        time: '20 Sep 2021 11:24 am',
      },
      {
        title: 'yvoone@howdesign.com.tw',
        time: '21 Sep 2021 11:24 am',
      },
    ];

    return {
      activityData,
      shareData,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-activeity-share-record {
  @include padding(0 24px);
}

.activity-wrap {
  height: 400px;
  overflow-y: auto;
  margin-top: 17px;
}

.activity-list {
  &__item {
    & + & {
      margin-top: 24px;
    }

    &__box {
      &__title {
        @include font-style($c-assist4, 13, normal, 0.7px, 20px);
      }
    }
  }
}

.activity-detail-list {
  margin-top: 8px;

  &__item {
    @include padding(22px 20px);
    background-color: $c-assist1;

    & + & {
      margin-top: 12px;
    }

    &__title {
      @include font-style($c-black, 16, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);

      margin-top: 8px;
    }
  }
}

.share-wrap {
  margin-top: 17px;
}

.share-input {
  @include flex();

  &__to {
    @include font-style($c-black, 16, normal, 0.8px, 22px);
  }

  &__box {
    flex: 1;
    @include flex();
    @include padding(14px 25px);
    margin-left: 17px;
    border: 1px solid $c-assist3;
    height: 40px;
    border-radius: 20px;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.share-list-wrap {
  margin-top: 24px;
}

.total-share-count {
  @include font-style($c-assist4, 14, normal, 0.7px, 20px);
}

.share-list {
  margin-top: 8px;

  height: 400px;
  overflow-y: auto;

  &__item {
    height: 90px;
    @include flex();
    @include padding(0 20px);
    background-color: $c-assist1;

    &+& {
      margin-top: 12px;
    }

    &__box {
      width: 100%;
      @include flex(space-between);

      &__left {
        @include flex();
      }

      &__right {
        @include flex();
      }
    }
  }
}

.left {
  @include flex();

  &__img {
    @include circle(45px);
    overflow: hidden;
  }

  &__info {
    margin-left: 20px;

    &__title {
      @include font-style($c-black, 16, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 28px);
    }
  }
}

.right {
  &__resend {
    height: 26px;
    @include flex(center);
    @include font-style($c-white, 14, normal, 0.7px, 20px);
    background-color: $c-assist15;
    border-radius: 13px;
    @include padding(0 10px);
  }

  &__delete {
    margin-left: 20px;
  }
}
</style>
